export const setYoutubePluginLang = (CKEDITOR) =>
  CKEDITOR.plugins.setLang('youtube', 'en', {
    button: 'Embed YouTube Video',
    title: 'Embed YouTube Video',
    txtEmbed: 'Paste Embed Code Here',
    txtUrl: 'Paste YouTube Video URL',
    txtWidth: 'Width',
    txtHeight: 'Height',
    chkRelated: "Show suggested videos at the video's end",
    txtStartAt: 'Start at (ss or mm:ss or hh:mm:ss)',
    chkPrivacy: 'Enable privacy-enhanced mode',
    chkOlderCode: 'Use old embed code',
    chkAutoplay: 'Autoplay',
    noCode: 'You must input an embed code or URL',
    invalidEmbed: "The embed code you've entered doesn't appear to be valid",
    invalidUrl: "The URL you've entered doesn't appear to be valid",
    or: 'or',
    noWidth: 'You must input a width',
    invalidWidth: "The width you input doesn't appear to be valid",
    noHeight: 'You must input a height',
    invalidHeight: "The height you input doesn't appear to be valid",
    invalidTime: "The time you input doesn't appear to be valid",
    txtResponsive: 'Make Responsive (ignore width and height, fit to width)',
    txtVideoTitle: 'Title',
    invalidVideoTitle: 'You must input a video title',
  });
