<template>
  <div>
    <v-row>
      <v-col class="pa-0">
        <v-btn icon :to="{ name: 'builder', query: { program: program.id } }">
          <v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
      </v-col>
      <v-col class="pa-0">
        <v-btn icon @click="onPreviewPage">
          <v-icon size="18">$smartphone</v-icon>
        </v-btn>
      </v-col>
      <v-col class="pa-0">
        <v-menu class="elevation-2">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              color="grey lighten-1"
              role="button"
              :aria-expanded="attrs['aria-expanded']"
              v-on="on"
              :disabled="loading"
              aria-controls="action-menu"
              aria-label="Action menu"
              type="button"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list id="action-menu" :disabled="loading" class="py-0">
            <v-list-item :to="{ name: 'builder', query: { program: program.id } }">
              <v-list-item-icon>
                <v-icon size="18">mdi-playlist-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit or resume working</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onDuplicate">
              <v-list-item-icon>
                <v-icon size="18">mdi-content-copy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Duplicate for a new project</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onPublish" v-if="program.status !== programsStatus.PUBLISHED">
              <v-list-item-icon>
                <v-icon size="18">mdi-upload-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Publish</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onArchive" v-if="program.status !== programsStatus.ARCHIVED">
              <v-list-item-icon>
                <v-icon size="18">mdi-archive-arrow-down-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Archive</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onCopyURL">
              <v-list-item-icon>
                <v-icon size="18">mdi-link</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Copy & share program link</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onPreviewPage">
              <v-list-item-icon>
                <v-icon size="18">$smartphone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Preview</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <confirm-publish-dialog
      v-if="publishProgramModal"
      v-model="publishProgramModal"
      :title="autoPublishLiterals.dialog.publish.title"
      :message="autoPublishLiterals.dialog.publish.message"
      :program="program"
      @confirm="onPublishComplete"
    />
  </div>
</template>
<script lang="ts">
  import { PROGRAMS_STATUS, PROGRAMS_STATUS_OPTIONS, AUTO_PUBLISH_LITERALS } from '@/constants/programs';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import config from '@/constants/config';
  import ClientService from '@/services/client/client';
  import { ConfirmPublishDialog } from '.';
  import { DigitalProgram } from '@/store/programs/types';

  export default Vue.extend({
    components: {
      ConfirmPublishDialog,
    },
    props: {
      program: {
        type: DigitalProgram,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
        publishProgramModal: false,
      };
    },
    computed: {
      programsStatus() {
        return PROGRAMS_STATUS;
      },
      autoPublishLiterals() {
        return AUTO_PUBLISH_LITERALS;
      },
    },
    methods: {
      fetchSport: ClientService.fetchSport,
      fetchClient: ClientService.fetchClient,
      ...mapActions('programs', {
        fetchPrograms: 'fetchPrograms',
        changeStatus: 'changeStatus',
        duplicate: 'duplicate',
      }),
      ...mapActions('builder', {
        setPagePreviewVisibility: 'setPagePreviewVisibility',
        setPagePreviewType: 'setPagePreviewType',
        setPageProgram: 'setPageProgram',
      }),
      ...mapActions('ui', { showToast: 'showToast' }),
      async onChange(status: number) {
        try {
          this.loading = true;
          const { id, status: oldStatus } = this.program;
          await this.changeStatus({ id, status, oldStatus });
        } catch (error) {
          this.handleError(error, 'Could not change program status');
        } finally {
          this.loading = false;
          const statusOption = PROGRAMS_STATUS_OPTIONS.find((o) => o.value == status).text;
          this.showToast(`Program status changed to <strong>${statusOption}</strong> successfully`);
        }
      },
      onPublish() {
        this.publishProgramModal = true;
      },
      onPublishComplete() {
        this.publishProgramModal = false;
      },
      async onArchive() {
        await this.onChange(PROGRAMS_STATUS.ARCHIVED);
      },
      onPreviewPage() {
        this.setPageProgram(this.program.id);
        this.setPagePreviewType('mobile');
        this.setPagePreviewVisibility(true);
      },
      async onCopyURL() {
        try {
          const { clientId } = this.program;
          const { clientSportId } = this.program.sport;

          const createUrl = async () => {
            const { tenantId } = await this.fetchClient(clientId);
            const { shortName: sportShortName = 'programs' } = await this.fetchSport({ clientId, clientSportId });
            return `${config.frontendUrl}/${tenantId}/${sportShortName}/${this.program.id}`;
          };

          const url = new ClipboardItem({
            'text/plain': createUrl(),
          });
          await navigator.clipboard.write([url]);

          this.showToast('Copied to clipboard');
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      },
      async onDuplicate() {
        try {
          this.loading = true;
          const { id } = this.program;
          const data = await this.duplicate({ id });
          const url = this.generateRouteUrl('builder', { program: data.id });
          this.showToast(
            `Program was duplicated successfully. Edit the program <a class='toast-link-white' href="${url}">here</a></a>`
          );
          this.$emit('update');
        } catch (error) {
          this.handleError(error, 'Could not duplicate program');
        } finally {
          this.loading = false;
        }
      },
      generateRouteUrl(name: string, query: Record<string, any>) {
        const route = this.$router.resolve({ name, query });
        return route.href;
      },
      handleError(error: any, msg?: string) {
        msg = error.response?.data || msg || 'Was not possible to comunicate with server';
        this.showToast(msg);
        // console.warn(error);
      },
    },
  });
</script>
<style lang="scss">
  .toast-link-white {
    color: white !important;
    font-weight: bold;
  }
</style>
