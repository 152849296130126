export const HTML_ANCHOR_POSITIONS = {
  0: { value: 0, name: 'Top Right', initials: 'TR' },
  1: { value: 1, name: 'Top Center', initials: 'TC' },
  2: { value: 2, name: 'Top Left', initials: 'TL' },
  3: { value: 3, name: 'Middle Right', initials: 'MR' },
  4: { value: 4, name: 'Middle Center', initials: 'MC' },
  5: { value: 5, name: 'Middle Left', initials: 'ML' },
  6: { value: 6, name: 'Bottom Right', initials: 'BR' },
  7: { value: 7, name: 'Bottom Center', initials: 'BC' },
  8: { value: 8, name: 'Bottom Left', initials: 'BL' },
};
