import { publicationUpload } from '@/utils/aws';
import ClientService from '@/services/client/client';
import ProgramService from '@/services/program/program';
import { publication } from '@/store/client/types';
import { v4 as uuidv4 } from 'uuid';

const publishProgramToClientPublications = async (
  clientId: number,
  programId: string | number,
  programUrl: string
): Promise<boolean> => {
  const { tenantId } = await ClientService.fetchClient(clientId);
  const { s3BucketUri } = await ClientService.fetchPublicationSettings(clientId);

  try {
    // backup current school publications page on S3
    const backupResult = await backupPublicationsPage(s3BucketUri, clientId);
    if (backupResult !== true) {
      throw 'A backup of the school publications could not be created';
    }

    // add or update Digital Program to school's publication page on S3
    const updateResult = await publishProgramToPublicationsPage(programId, programUrl, s3BucketUri);
    if (updateResult === true) {
      return Promise.resolve(true);
    } else {
      throw "The school's publications could not be updated";
    }
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const backupPublicationsPage = async (s3BucketUri: string, clientId: number): Promise<boolean> => {
  const publicationPage = await ClientService.fetchPublicationPage(s3BucketUri);
  const publicationPageBlob = new Blob([JSON.stringify(publicationPage)], {
    type: 'application/json',
  });
  const client = await ClientService.fetchClient(clientId);
  const tenant = client.tenantId as string;

  try {
    const result = await publicationUpload(publicationPageBlob, tenant, `data_${Date.now()}.json`);
    if (result.status == 200) {
      return Promise.resolve(true);
    } else {
      throw 'A backup of the school publications could not be created';
    }
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const publishProgramToPublicationsPage = async (
  programId: string | number,
  programUrl: string,
  s3BucketUri: string
): Promise<boolean> => {
  const {
    clientId,
    title: programTitle,
    landingPage,
    sport,
    publishDate,
  } = await ProgramService.fetchProgram(programId);

  const clientSport = await ClientService.fetchSport({
    clientId: clientId,
    clientSportId: sport.clientSportId,
  });

  const newPublication: publication = {
    created_on: new Date().toISOString(),
    description: '',
    id: uuidv4(),
    file: {
      image: landingPage?.image?.url ?? '',
      title: programTitle,
      description: `Author: LEARFIELD Digital Publications, Catalog: ${programTitle} Published: ${new Date(
        publishDate
      ).toDateString()}`,
      url: programUrl,
      embed_url: '',
      slug: slugify(programTitle),
    },
    image: '',
    title: programTitle,
    updated_on: new Date().toISOString(),
    url: '',
    sport: null,
    sports: [],
  };

  if (clientSport) {
    newPublication.sports = [
      {
        id: sport.clientSportId,
        shortname: clientSport.shortName,
        slug: slugify(clientSport.name),
        title: clientSport.name,
      },
    ];
  }

  const publicationPage = await ClientService.fetchPublicationPage(s3BucketUri);
  const publicationIndex = publicationPage.publications.findIndex(
    (publication: publication) => publication.file.url === newPublication.file.url
  );

  if (publicationIndex > -1) {
    // replace publication
    publicationPage.publications[publicationIndex] = newPublication;
  } else {
    // add new publication
    publicationPage.publications.unshift(newPublication);
  }

  const publicationPageBlob = new Blob([JSON.stringify(publicationPage)], {
    type: 'application/json',
  });

  const client = await ClientService.fetchClient(clientId);
  const tenant = client.tenantId as string;

  try {
    const uploadResult = await publicationUpload(publicationPageBlob, tenant, 'data_live.json');
    if (uploadResult.status == 200) {
      return Promise.resolve(true);
    } else {
      throw "The school's publications could not be updated";
    }
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const slugify = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
    .trim();
};

export { publishProgramToClientPublications };
