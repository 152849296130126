<template>
  <v-card outlined :class="{ disabled }">
    <v-toolbar flat>
      <v-toolbar-title class="text-h6">{{ config.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="text-capitalize" depressed color="grey lighten-4" :disabled="disabled" @click="toggleModal"
        >Edit</v-btn
      >
    </v-toolbar>
    <v-card-text class="pt-0">
      <label for="text-area">{{ config.label }}</label>
      <v-sheet
        outlined
        rounded="lg"
        class="overflow-auto pa-2"
        :class="{ 'border-grey': !disabled }"
        min-height="32"
        max-height="200"
        v-html="value.html"
        :disabled="disabled"
      ></v-sheet>
    </v-card-text>
    <v-card-text v-if="config.hasAnchorPosition" class="pt-0">
      <label for="select">Anchor position</label>
      <v-select
        :value="selectedAnchorPosition"
        :items="anchorPositions"
        :disabled="disabled || !value.html"
        @change="$emit('setAnchorPosition', $event)"
        item-text="name"
        item-value="value"
        outlined
      ></v-select>
    </v-card-text>
    <html-modal
      :title="config.title"
      :label="config.label"
      :placeholder="config.placeholder"
      v-model="modal"
      :data="value"
      @submit="$emit('input', $event)"
    />
  </v-card>
</template>
<script lang="ts">
  import Vue from 'vue';
  import HtmlModal from './HtmlModal.vue';
  import { HTML_ANCHOR_POSITIONS } from '../../constants/htmlAnchorPositions';
  export default Vue.extend({
    name: 'HtmlBlock',
    components: {
      HtmlModal,
    },
    props: {
      disabled: {
        type: Boolean,
        required: false,
      },
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
      selectedAnchorPosition: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {
        modal: false,
      };
    },
    computed: {
      preview(): string {
        return this.value.html;
      },
      anchorPositions(): Array<{ name: string; value: number }> {
        return Object.values(HTML_ANCHOR_POSITIONS);
      },
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
    },
  });
</script>
<style scoped lang="scss">
  .border-grey {
    border-color: rgba(0, 0, 0, 0.38) !important;

    &:hover {
      border-color: rgba(0, 0, 0, 0.86) !important;
    }
  }
  .disabled {
    opacity: 0.5;
  }
</style>
