<template>
  <div>
    <v-card-text class="scrollable">
      <label class="text--secondary">Select players to import</label>
      <v-data-table
        class="elevation-2 mt-2 custom-table"
        :class="{ invalid: !isFormValid }"
        checkbox-color="primary"
        :headers="headers"
        disable-pagination
        hide-default-footer
        show-select
        fixed-header
        sort-by="firstName"
        item-key="rosterPlayerId"
        :items="players"
        v-model="selected"
        height="360"
        :loading="isLoading"
      ></v-data-table>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack" :disabled="isFormDisabled">
        Back
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        depressed
        class="text-capitalize"
        :disabled="!isFormValid || isFormDisabled || !isValid"
        @click="onSubmit"
      >
        Continue
      </v-btn>
      <span v-if="!isFormValid" class="error--text">Select a least one player</span>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset" :disabled="isFormDisabled">
        Clear
      </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import ClientService from '@/services/client/client';
  import { mapActions, mapGetters } from 'vuex';
  import { OpponentRosterPlayer, PlayerDetails } from '@/store/client/types';
  import { sortBy } from 'lodash-es';
  interface Injections {
    setPageTitle: (title: string) => void;
    setPageContent: (content: any) => void;
    toggleLoading: () => void;
    getPageSport: () => any;
    getPageEvent: () => any;
    goNext: () => void;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'RosterImporter',
    inject: [
      'setPageTitle',
      'setPageContent',
      'toggleLoading',
      'getPageSport',
      'getPageEvent',
      'goNext',
      'goBack',
      'close',
    ],
    data() {
      return {
        opponentSiteUrl: '',
        opponentTenantId: '',
        globalSportId: 0,
        players: [] as OpponentRosterPlayer[],
        selected: [] as OpponentRosterPlayer[],
        headers: [
          {
            text: 'First Name',
            align: 'start',
            value: 'firstName',
          },
          {
            text: 'Last Name',
            align: 'start',
            value: 'lastName',
          },
          {
            text: 'Position',
            align: 'start',
            value: 'positionLong',
            width: 100,
          },
          {
            text: 'Class',
            align: 'start',
            value: 'academicYearLong',
            width: 180,
          },
        ],
        isLoading: false,
        isFormValid: true,
        isFormDisabled: false,
        isValid: true,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchSport: ClientService.fetchSport,
      fetchRosterPlayers: ClientService.fetchOpponentRosterPlayers,
      fetchPlayers: ClientService.fetchPlayers,
      fetchClient: ClientService.fetchClient,
      fetchScheduleEventInfo: ClientService.fetchScheduleEventInfo,
      fetchGlobalSport: ClientService.fetchGlobalSport,
      fetchOpponentInfo: ClientService.fetchOpponentInfo,
      fetchOpponentRosterBio: ClientService.fetchOpponentRosterBio,
      getOpponentTenantId: ClientService.getOpponentTenantId,
      printHeight(player: any) {
        return `${player.heightFeet}′${player.heightInches}″`;
      },
      async importRoster() {
        let teamInfo = this.program.event.away;
        if (teamInfo.name === '') {
          let pageEvent = this.getPageEvent();
          teamInfo = pageEvent.opponent;
        }

        const globalSport = await this.fetchGlobalSport({
          globalSportId: this.globalSportId,
          tenantId: this.opponentTenantId,
        });
        const sportName = globalSport.shortDisplay;

        const headerText = `${teamInfo.name} ${sportName} Roster`;
        this.setPageTitle(`${headerText} Import`);

        let playersMap: PlayerDetails[] = [];
        for (const selected of this.selected) {
          const playerBio = await this.fetchOpponentRosterBio({
            tenantId: this.opponentTenantId,
            rosterPlayerId: selected.rosterPlayerId,
          });

          const httpRegex = /(http|https)/;
          if (playerBio.image && playerBio.image.absoluteUrl !== '' && !httpRegex.test(playerBio.image.absoluteUrl)) {
            playerBio.image.absoluteUrl = `${this.opponentSiteUrl}${playerBio.image.absoluteUrl}`;
          }

          const player = {} as PlayerDetails;
          player.bio = playerBio.bio;
          player.class = playerBio.academicYearLong;
          player.position = playerBio.positionShort;
          player.height = this.printHeight(playerBio);
          player.weight = playerBio.weight;
          player.hometown = playerBio.hometown;
          player.firstName = playerBio.firstName;
          player.lastName = playerBio.lastName;
          player.isCaptain = playerBio.isCaptain;
          player.actionShot = playerBio.image?.absoluteUrl ? playerBio.image?.absoluteUrl : '';
          player.highSchool = playerBio.highSchool;
          player.jerseyNumber = playerBio.jerseyNumber;
          player.previousSchool = playerBio.previousSchool;
          player.rosterHeadshot = playerBio.image?.absoluteUrl ? playerBio.image?.absoluteUrl : '';
          player.actionShotAltText = playerBio.image?.altText;
          player.rosterHeadshotAltText = playerBio.image?.altText;
          player.clientRosterPlayerId = playerBio.rosterPlayerId;
          playersMap.push(player);
        }

        const players = sortBy(Object.values(playersMap), ['lastName', 'firstName']);
        return {
          blocks: [
            {
              id: 1,
              data: { text: headerText },
              type: 'header',
              config: {
                label: 'Describe what this page is about',
                title: 'Page Heading',
                placeholder: 'Page Heading Text Goes Here...',
              },
            },
            {
              id: 2,
              type: 'textimage',
              config: {
                label: 'Choose which team this page is associated with',
                title: 'Team Heading',
                imageLabel: 'School Logo',
                displayConfig: true,
                firstTextDefault: 'mascot',
                secondTextDefault: 'name',
                placeholderFirstText: 'School Nickname/Mascot',
                placeholderSecondText: 'School Name',
              },
              data: {
                alt: `${teamInfo.mascot} team logo`,
                image: teamInfo.logoUrl,
                firstText: teamInfo.mascot,
                secondText: teamInfo.name,
                locationType: 'away',
              },
            },
            {
              id: 3,
              data: {
                players,
              },
              type: 'roster',
              config: {
                title: 'Roster Panel',
                subtitle: 'Components that build your roster page. Player bio & stats included within player cards',
              },
            },
          ],
        };
      },
      async onSubmit() {
        this.isFormValid = !!this.selected.length;
        if (!this.isFormValid || !this.isValid) return;
        try {
          this.toggleLoading();
          this.isFormDisabled = true;
          const content = await this.importRoster();
          this.setPageContent(content);
          this.goNext();
        } catch (error) {
          const msg = error?.response?.data || 'Could not import roster data';
          this.showToast(msg);
          // // console.warn(error);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
      reset() {
        this.selected = [];
        this.isFormValid = true;
      },
    },
    async created() {
      try {
        this.isFormDisabled = true;
        this.isLoading = true;

        const { clientId } = this.program;
        const client = await this.fetchClient(clientId);
        const { tenantId } = client;

        this.globalSportId = this.program.sport.globalSportId;
        if (!this.globalSportId) {
          let pageSport = this.getPageSport();
          this.globalSportId = pageSport.globalSportId;
        }

        let eventId = this.program.event.scheduleId;
        if (!eventId) {
          let pageEvent = this.getPageEvent();
          eventId = pageEvent.id;
        }
        const event = await this.fetchScheduleEventInfo({ clientId, eventId });
        const {
          opponent: { id: opponentId },
        } = event;

        const opponentInfo = await this.fetchOpponentInfo({ clientId, opponentId });
        const opponentGlobalId = opponentInfo?.opponentGlobalId;
        const opponentGlobal = await this.getOpponentTenantId(opponentGlobalId.toString());
        this.opponentTenantId = opponentGlobal.client_abbreviation;
        this.opponentSiteUrl = opponentGlobal.website;

        const globalSport = await this.fetchGlobalSport({
          globalSportId: this.globalSportId,
          tenantId: this.opponentTenantId,
        });
        const { rosterId } = globalSport;

        const players = await this.fetchRosterPlayers({ rosterId, tenantId: this.opponentTenantId });
        this.players = sortBy(Object.values(players), ['lastName', 'firstName']);
        this.isValid = true;
      } catch (error) {
        this.isValid = false;
        this.showToast('Could not fetch roster data');
        // // console.warn(error);
      } finally {
        this.isFormDisabled = false;
        this.isLoading = false;
      }
    },
    watch: {
      selected(selected: OpponentRosterPlayer[]) {
        if (selected && selected.length) this.isFormValid = true;
      },
    },
  });
</script>
