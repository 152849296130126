<template>
  <div>
    <v-card outlined>
      <v-toolbar flat>
        <v-toolbar-title class="text-h6">Landing Page Options</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="px-6 py-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-radio-group v-model="formData.isCover" row class="pt-0 mt-0">
            <!--<v-radio label="Countdown View" :value="false"></v-radio>-->
            <v-radio label="Full Page Cover Image" :value="true"></v-radio>
          </v-radio-group>
          <label class="grey--text text--darken-1">Cover image</label>
          <upload-image
            :value="value.image.url"
            @input="formData.image.url = $event"
            :fileUrl="value.image.url"
            :clientId="clientId"
            :requiredHeight="formData.isCover ? null : 1920"
            :requiredWidth="formData.isCover ? null : 1600"
            class="mb-5 mt-2"
            allow-pdf
          />
          <label class="grey--text text--darken-1">Alt Text</label>
          <v-text-field
            class="mt-2"
            autofocus
            autocomplete="off"
            dense
            name="text-field"
            outlined
            v-model="formData.image.alt"
            required
            :rules="altRules"
          ></v-text-field>

          <label class="grey--text text--darken-1">Image URL Redirect Link</label>
          <v-text-field
            class="mt-2"
            autocomplete="off"
            dense
            name="text-field"
            outlined
            v-model="formData.image.link"
          ></v-text-field>
        </v-form>
      </v-card-text>
    </v-card>
    <br />

    <div class="d-flex flex-column align-stretch gap-4">
      <HtmlBlock
        v-model="htmlBlock"
        :config="{ label: 'Body', title: 'Landing Page Body Text', hasAnchorPosition: true }"
        :selectedAnchorPosition="anchorPosition"
        :disabled="fileExtension === 'pdf'"
        @setAnchorPosition="setAnchorPosition"
      />
    </div>
    <br />
  </div>
</template>
<script lang="ts">
  import { HtmlBlock } from '@/components/Builder';
  import UploadImage from '@/components/Ui/UploadImage.vue';

  import Vue from 'vue';
  const LandingPage = Vue.extend({
    name: 'LandingPage',
    components: {
      UploadImage,
      HtmlBlock,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
      clientId: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {
        valid: true,
        altRules: [
          (v: string) => {
            if (this.$data.formData.image.url == null || this.$data.formData.image.url == '') return true;
            return !!v || 'Alt Text is required';
          },
        ],
        htmlBlock: {
          html: this.value.content,
        },
        anchorPosition: this.value.anchorPosition,
        formData: {
          image: {
            url: this.value.image.url || null,
            link: this.value.image.link || null,
            alt: this.value.image.alt || null,
          },
          isCover: true, // set as true temporally DIGPROGRAM-1255
          content: this.value.content,
          anchorPosition: this.value.anchorPosition,
        },
      };
    },
    methods: {
      validate() {
        return this.form.validate();
      },
      setAnchorPosition(newValue: any) {
        this.anchorPosition = newValue;
        this.formData.anchorPosition = newValue;
      },
    },
    computed: {
      form(): VForm {
        return this.$refs.form as VForm;
      },
      fileExtension(): string {
        return this.formData?.image?.url?.split('.').pop();
      },
    },
    watch: {
      formData: {
        handler: function (data) {
          this.$emit('input', data);
        },
        deep: true,
      },
      'htmlBlock.html': {
        handler(newValue) {
          this.formData.content = newValue;
          if (!newValue) {
            this.anchorPosition = undefined;
          }
        },
        immediate: true,
      },
    },
  });
  export type LandingPageRef = InstanceType<typeof LandingPage>;
  export default LandingPage;
  export type VForm = Vue & { validate: () => boolean };
</script>
